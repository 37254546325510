<template>
  <div class="manager" v-show="show">
    <div class="btn-container">
      <a-button type="primary" class="mr-2" @click="addManagerVisible = true" v-if="permission">{{
        $t('companyInfo.btn_new_manager')
      }}</a-button>
      <BatchImport @reload="reload" />
      <a-button type="link" :href="$g.order_manager_info_template">
        {{ $t('companyInfo.btn_download') }} <a-icon type="download" />
      </a-button>
    </div>
    <!-- 下单经理列表 table -->
    <a-table
      :pagination="pagination"
      class="head-bg no-br"
      size="middle"
      rowKey="id"
      bordered
      :rowClassName="getRowClass"
      :data-source="managerInfo"
      :loading="managerLoading"
    >
      <!-- 姓名 -->
      <a-table-column :title="$t('managerInfo.name')" key="name" align="center" :width="150">
        <template slot-scope="text">
          {{ text.name | defined }}
        </template>
      </a-table-column>
      <!-- 邮箱 -->
      <a-table-column :title="$t('managerInfo.email')" key="email" :width="200">
        <template slot-scope="text">
          {{ text.email | defined }}
        </template>
      </a-table-column>
      <!-- 手机 -->
      <a-table-column :title="$t('managerInfo.phone_number')" key="phone" :width="200">
        <template slot-scope="text">
          {{ text.phone === '' ? '---' : text.phone | defined }}
        </template>
      </a-table-column>
      <!-- 部门 -->
      <a-table-column :title="$t('managerInfo.department')" key="department">
        <template slot-scope="text">
          {{ text.department === '' ? '---' : text.department | defined }}
        </template>
      </a-table-column>
      <!-- 权限 -->
      <a-table-column :title="$t('managerInfo.senior_manager')" key="seniorManager">
        <template slot-scope="text">
          {{ getRole(text.type) | defined }}
        </template>
      </a-table-column>
      <!-- 状态 -->
      <a-table-column :title="$t('managerInfo.statu')" key="status" align="center">
        <template slot-scope="text">
          <span :class="text.status ? 'job' : 'departure'">
            {{ text.status ? $t('managerInfo.job') : $t('managerInfo.departure') | defined }}
          </span>
        </template>
      </a-table-column>
      <!-- 加入时间 -->
      <a-table-column :title="$t('managerInfo.join_time')" key="createDate">
        <template slot-scope="text">
          {{ text.createDate | dateFormat }}
        </template>
      </a-table-column>
      <!-- 操作 -->
      <a-table-column :title="$t('companyInfo.action')" key="action" v-if="permission" width="230px">
        <template slot-scope="record">
          <!-- 编辑下单经理 -->
          <a v-if="record.status" class="mr-3" @click="onEditManager(record)">{{ $t('companyInfo.action_edit') }}</a>
          <a v-else class="mr-3" :style="{ opacity: 0 }">{{ $t('companyInfo.action_edit') }}</a>
          <!-- 注销下单经理 -->
          <a @click="onLogout(record)">{{
            record.status ? $t('managerInfo.set_departure') : $t('managerInfo.set_jon')
          }}</a>
        </template>
      </a-table-column>
    </a-table>

    <!-- 新增下单经理侧抽屉 -->
    <a-drawer
      width="460px"
      :title="managerEdit ? $t('companyInfo.edit_manager_title') : $t('companyInfo.new_manager_title')"
      placement="right"
      :visible="addManagerVisible"
      @close=";(addManagerVisible = false), (managerEdit = false)"
    >
      <AddManager
        v-on:show="editManagerInfo"
        :id="agencyInfo.id"
        :agencyInfo="agencyInfo"
        v-if="addManagerVisible"
        :managerInfo="selectedManager"
        :edit="managerEdit"
      />
    </a-drawer>
  </div>
</template>

<script>
import AddManager from '../AddManager'
import BatchImport from './BatchImport'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    agencyInfo: {
      type: Object,
    },
  },
  inject: ['managerList'],
  components: {
    AddManager,
    BatchImport,
  },
  data() {
    return {
      addManagerVisible: false,
      permission: true || undefined,
      managerFileList: [],
      selectedManager: {},
      managerLoading: false,
      managerEdit: false,
      managerInfo: [],
      pagination: {
        current: 1,
        size: 'middle',
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1
          this.pagination.pageSize = pageSize
          this.getManagerInfo()
        },
        onChange: (page) => {
          this.pagination.current = page
          this.getManagerInfo()
        },
      },
    }
  },
  watch: {
    show(v) {
      if (v) {
        this.fetchAll()
      }
    },
  },
  mounted() {
    this.fetchAll()
  },
  methods: {
    getRole(type) {
      const result = this.managerList.find((_) => _.value === type)?.label
      return result
    },
    reload() {
      this.resetPage()
      this.getManagerInfo()
    },
    getRowClass(record) {
      if (record.status === 0) {
        return 'c-3'
      }
    },
    fetchAll() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getManagerInfo()
    },
    async getManagerInfo() {
      try {
        this.managerLoading = true
        const params = {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('cusEmployeeList', params)
        this.managerLoading = false
        this.managerInfo = data.list
        this.pagination.total = data.total
        console.log(`data`, data)
      } catch (err) {
        this.managerLoading = false
        this.$httpNotify(err)
      }
    },
    // 编辑下单经理
    onEditManager(record) {
      this.selectedManager = record
      this.addManagerVisible = true
      this.managerEdit = true
    },
    resetPage() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
    },
    // 下单经理子组件触发父组件
    editManagerInfo() {
      this.reload()
      this.addManagerVisible = false
      this.managerEdit = false
    },

    // 注销下单经理
    async onLogout(record) {
      try {
        console.log(`record`, record)
        const status = record.status ? 0 : 1
        const params = { id: record.id, status }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('cusEmployeeUpdateStatus', params)
        this.$message.success(this.$t('prompt.set_success'))
        this.getManagerInfo()
        console.log(`data`, data)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.btn-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
